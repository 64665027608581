
/*
 * HEADER
 */

.page-sidebar {
  color: #f0f0f0;

  h2 {
    color: #b0b0b0;
    margin-top: 15px;
    font-size: 12px;

    text-transform: uppercase;

    padding-left: 15px;
  }


  .sidebar-header {
    color: #f0f0f0;

    display: flex;
    flex-direction: row;
    background: #222;

    height: 58px;

    .logo {
      flex: 0 0 50%;
      padding: 10px 15px;

      .logo-icon {
        margin-top: 5px;
      }
    }

    .title {
      flex: 1;

      h1 {
        margin: 7px 0px 0px 0px;
        padding: 3px 0px;
        font-size: 13px;
        color: #f0f0f0;
      }
      p {
        font-size: 10px;
        color: #f0f0f0;
      }
    }
  }


  .routeservers-list {
    margin-top: 40px;

    > ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
        li {
          padding: 8px;
          padding-left: 15px;

          cursor: pointer;
        }

        li.active {
        }
    }

    .routeserver-id {
      font-size: 13px;
      display: block;
    }

    a.routeserver-id {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .routeserver-status {
      .bird-version {
        font-size: 10px;
        color: #888888;
      }

      .api-error {
        font-size: 10px;
        color: #aa0000;
      }
    }

  }

  .routeservers-groups-select {
    display: block;
    padding: 0px 3px;
    
    .dropdown {
      .dropdown-menu {
         display: block;
      }
    }

    .rs-group-dropdown {
        .dropdown-options {
          display: none;
        }

        &.open {
            .dropdown-options {
                display: flex;
            }
        }
    }

    .dropdown-options {
       position: absolute;
       z-index: 999;
       background: #fbfbfb;
       border-radius: 4px;
       box-shadow: 2px 2px 3px #aaa;
       flex-direction: row;
       justify-content: space-between;
       flex-wrap: wrap;
       // column-gap: 15px;

       ul {
         flex: 1;
         margin: 0;
         padding: 15px;
       }
       li {
         padding: 3px;
         list-style: none;
         border-radius: 4px;

         button {
           border-radius: 3px;
           padding: 3px 8px; 

           &:hover {
             color: #337ab7;
           }
         }
       }
    }

    .btn-select {
      display: block;
      width: 100%;
      background: #222;
      border: #222;
      text-align: left;
      color: white;
      text-shadow: none;

      .caret {
        float: right;
        margin-top: 10px;
      }

      &:focus,
      &:hover {
        background: #222;
        color: white;
        text-shadow: none;
      }

      &:focus {
        box-shadow: 0px 1px 0px #555;
      }
    }
    
    .btn-option {
        display: block;
        text-align: left;
        color: #222;
        width: 100%;
        padding: 2px 10px;

        &:hover {
            background: #ccc;
            text-decoration: none;
        }
    }


  }

}
